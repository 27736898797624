import { render, staticRenderFns } from "./MaterialFactoryManagement.vue?vue&type=template&id=6d5a9fe0&scoped=true"
import script from "./MaterialFactoryManagement.vue?vue&type=script&lang=js"
export * from "./MaterialFactoryManagement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d5a9fe0",
  null
  
)

export default component.exports